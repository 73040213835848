import { OrganizationEntityType } from "@/lib/supabase/supabaseTypes";
import { PdfTemplateConfiguration } from "../../context/PdfTemplateConfigurationContext";
import { PdfTemplateType } from "../document-template.types";
import { Dot } from "lucide-react";
import clsx from "clsx";
import { Alignment } from "../pdf-templates/types";
import parse from "html-react-parser";
import { getConfig } from "../../context/util";
import { OrganizationsImg } from "./organizations-img.component";

export const PdfLetterhead: React.FC<{
	organization: OrganizationEntityType;
	pdfTemplateConfiguration: PdfTemplateConfiguration;
	pdfType: PdfTemplateType;
}> = ({ organization, pdfTemplateConfiguration, pdfType }) => {
	const showLogo = pdfTemplateConfiguration[pdfType].showLogo;

	const generalConfig = pdfTemplateConfiguration.general;
	const pdfConfig = pdfTemplateConfiguration[pdfType] || {};

	const config = getConfig(pdfConfig, generalConfig);

	const logoSize = generalConfig.logoSize ?? 15;

	return (
		<div aria-label="Briefkopf" style={{ height: "45mm" }}>
			<div
				aria-label="Oberhalb der Trennlinie mit Firmenlogo, Adresse, Kontaktinformationen und Mitgliedschaftsstatus"
				style={{
					borderBottom:
						config.showBorder == true ? "1px solid black" : "",

					// space between vertically
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					height: `${logoSize + 25}mm`,
				}}
			>
				{showLogo && (
					<div
						style={{
							height: `${(logoSize ?? 20) + 7}mm`,
							paddingTop: "4mm",
							paddingBottom: "3mm",
							overflow: "hidden",
							display: "flex",
							justifyContent:
								generalConfig.logoAlignment ?? "left",
						}}
					>
						<img
							alt="logo"
							style={{
								objectFit: "cover",
								height: `${logoSize ?? 20}mm`,
							}}
							src={generalConfig.logoImgurl}
						/>
					</div>
				)}
				{!config.hideLabAddress && (
					<div>
						{config.addressRtfEnabled ? (
							<div>{parse(config.addressRtfText ?? "")}</div>
						) : (
							<Address
								organization={organization}
								email={generalConfig?.email}
								oneLiner={config.oneLiner ?? false}
								side={config.addressAlignment}
							/>
						)}
					</div>
				)}
				{!config.hideMembers && (
					<div
						aria-label="Kontaktinformationen und Mitgliedschaftsstatus"
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							paddingBottom: "1mm",
							fontSize: "10pt",
							alignItems: "center",
						}}
					>
						{config.organizationsImgPosition == "top" && (
							<OrganizationsImg
								config={config}
								generalConfig={generalConfig}
							/>
						)}
					</div>
				)}
			</div>
			{!config.hideIds && (
				<div>
					{config.idRtfEnabled ? (
						<>{parse(config.idRtfText ?? "")}</>
					) : (
						<IdNumbers
							organization={organization}
							logoSize={logoSize ?? 18}
							oneLiner={config.idOneLiner}
							idAlignment={config.idAlignment}
						/>
					)}
				</div>
			)}
		</div>
	);
};

const Address = ({
	organization,
	oneLiner,
	email,
	side,
}: {
	organization: OrganizationEntityType;
	email: string;
	oneLiner: boolean;
	side?: "left" | "right" | "center";
}) => {
	return (
		<div className="mt-2">
			{oneLiner ? (
				<div className="flex flex-col gap-1  items-center">
					<p className="flex  items-center whitespace-nowrap gap-0.5">
						{organization?.title_or_company_name ||
							organization?.name}
					</p>
					<div className="flex gap-0 text-sm items-center">
						<p className="flex items-center whitespace-nowrap gap-0.5">
							{organization?.name_or_additional_information}
							<Dot />
						</p>
						<p className="flex items-center whitespace-nowrap gap-0.5">
							{organization?.street}
							<Dot />
						</p>
						<p className="flex items-center whitespace-nowrap gap-0.5">
							{organization?.city}
							<Dot />
						</p>
						<p className="flex items-center whitespace-nowrap gap-0.5">
							{organization?.phone_and_fax}
							<Dot />
						</p>
						<p className="flex items-center whitespace-nowrap gap-0.5">
							{email}
						</p>
					</div>
				</div>
			) : (
				<div
					className={clsx("w-full flex flex-col ", {
						"items-end": side === "right",
						"items-start": side === "left",
						"items-center": side === "center",
					})}
				>
					<div aria-label="Firmenadresse">
						<p>
							{organization?.title_or_company_name ||
								organization?.name}
						</p>
						<p>{organization?.name_or_additional_information}</p>
						<p>{organization?.street}</p>
						<p>{organization?.city}</p>
					</div>

					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							gap: "2mm",
						}}
					>
						<p>{organization?.phone_and_fax}</p>
						<p>{email}</p>
					</div>
				</div>
			)}
		</div>
	);
};

const IdNumbers = ({
	organization,
	oneLiner,
	idAlignment,
}: {
	organization: OrganizationEntityType;
	oneLiner?: boolean;
	logoSize: number;
	idAlignment?: Omit<Alignment, "center">;
}) => {
	const alignmentTerms = {
		left: "start",
		right: "end",
	};
	return (
		<>
			{oneLiner ? (
				<>
					<div
						className="flex text-[8px] gap-0 h-[5mm] items-center"
						style={{
							justifyContent: (idAlignment ?? "left") as
								| "left"
								| "right",
						}}
					>
						{organization.mwst_number && (
							<p className="flex items-center whitespace-nowrap gap-0.5">
								MwSt.-Nummer: {organization.mwst_number}
								<Dot />
							</p>
						)}
						<p className="flex items-center whitespace-nowrap gap-0.5">
							GLN: {organization.gln}
						</p>
					</div>
				</>
			) : (
				<div
					className={`flex w-full flex-col text-xs gap-0`}
					style={{
						alignItems:
							alignmentTerms[
								(idAlignment ?? "left") as "left" | "right"
							],
					}}
				>
					{organization.mwst_number && (
						<p className="flex items-center whitespace-nowrap gap-0.5">
							MwSt.-Nummer: {organization.mwst_number}
						</p>
					)}
					<p className="flex items-center whitespace-nowrap gap-0.5">
						GLN: {organization.gln}
					</p>
				</div>
			)}
		</>
	);
};
