import { createSecurePatientName } from "../../lib/utils/utils-functions";
import { usePractitionersContext } from "../../context/single-entity-contexts/PractitionersContext";
import { useTeamProfilesContext } from "../../context/single-entity-contexts/TeamProfilesContext";
import { useCentralStore } from "../../store/Central";
import { usePatientStore } from "../../store/Patient";

// Hooks that return the name of an entity

/**
 * Use this if the component already has the patient first and last name and wants to know how to display it
 * @param firstName
 * @param lastName
 */
export function usePatientNameByFirstAndLastName(
	firstName: string | null,
	lastName: string | null
) {
	const { profile } = useCentralStore((state) => ({
		profile: state.profile,
	}));
	const isPrivacyOn = !!profile?.data_privacy_mode;
	return {
		name: createSecurePatientName(firstName, lastName, isPrivacyOn),
	};
}

/**
 * Use this if the component only has the patient id and wants to know how to display it
 */
export function usePatientNameByPatientId() {
	const { patientsLookup } = usePatientStore((state) => ({
		patientsLookup: state.patientsLookup,
	}));

	const { profile } = useCentralStore((state) => ({
		profile: state.profile,
	}));
	const isPrivacyOn = !!profile?.data_privacy_mode;

	const getPatientNameByPatientId = (patientId: string): string => {
		const patient = patientsLookup[patientId];
		if (!patient) {
			return "";
		}

		const { first_name, last_name } = patient;
		return createSecurePatientName(first_name, last_name, isPrivacyOn);
	};

	return { getPatientNameByPatientId };
}

/**
 *
 * @param clientId
 * @example const { clientName } = useClientName(sharedWithClientId);
 */
export function useClientName(clientId: string) {
	const clientsLookup = useCentralStore((state) => state.clientsLookup);

	const client = clientsLookup[clientId];
	const name = client ? `${client.first_name} ${client.last_name}` : "";

	return { name };
}

// It might seem counterintuitive to not use the profiles table to get the name of the practitioner.
// However, it's not useful to have a context that includes both profiles of lab users and practitioner profiles or only practitioner profiles.
export function usePractitionerName(profileId: string) {
	const {
		practitioners: { practitionersLookupByAuthId },
	}: any = usePractitionersContext();

	const practitioner = practitionersLookupByAuthId[profileId];
	const name = practitioner
		? `${practitioner.first_name} ${practitioner.last_name}`
		: "";

	return { name };
}

// Note: This only queries users that are part of TeamProfilesContext, i.e. only lab users, not practitioners.
export function useUserName(profileId: string) {
	const {
		teamProfiles: { teamProfilesLookup },
	}: any = useTeamProfilesContext();

	const user = teamProfilesLookup[profileId];
	const name = user ? `${user.first_name} ${user.last_name}` : "";

	return { name };
}
