import { useRefContext } from "@/dentlab/src/context/RefContext";
import { calculateJobTotalsLegacy } from "@/dentlab/src/lib/utils/calculate";
import {
	JobDocumentEntityType,
	JobEntityType,
	JobItemEntityType,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { Button, Portal } from "@mui/material";
import { RightSidebar } from "../../job-page-components/right-sidebar.component";
import { CustomTextFieldWithPercentage } from "@/dentlab/src/forms/form-input-components/custom-input-components/custom-text-field-with-percentage.component";
import { useForm } from "@/dentlab/src/hooks/useForm/useForm";
import { Database } from "@/lib/supabase";
import { JobStatusEnum } from "@/lib/types/job";
import { useToast } from "@/dentlab/src/context/ToastContext";
import { useCentralStore } from "@/dentlab/src/store/Central";

export const TotalSidebar: React.FC<{
	jobItems: JobItemEntityType[];
	jobDocument: JobDocumentEntityType;
	job: JobEntityType;
	onClose: () => void;
}> = ({ jobItems, job, jobDocument, onClose }) => {
	// Container for setting sidebars on the job page
	const { container } = useRefContext();
	const { organization } = useCentralStore((state) => ({
		organization: state.organization,
	}));
	const organizationId = organization?.id;
	const { showToast } = useToast();

	const { handleInputChange, handleUpdate, formData } = useForm<
		Database["public"]["Tables"][SupabaseTableEnum.JOB_DOCUMENTS]["Update"]
	>(
		{
			organization_id: organizationId,
		},
		SupabaseTableEnum.JOB_DOCUMENTS,
		true,
		jobDocument.id,
		"Dokument"
	);

	if (!organization) return null;

	const {
		total,
		mwst,
		amountMaterials,
		amountWork,
		deductions,
		taxpunkte,
		amountFixedRate,
		amountPostage,
		amountExternalWork,
	} = calculateJobTotalsLegacy({ job, jobDocument, jobItems, organization });

	const rows = [
		{
			name: "ArbeitTP",
			value: taxpunkte.text,
		},
		{
			name: "Arbeit",
			value: amountWork.text,
		},
		{
			name: "Material",
			value: amountMaterials.text,
		},
		{
			name: "Fremdarbeiten",
			value: amountExternalWork.text,
		},
		{
			name: "Porto",
			value: amountPostage.text,
		},
		{
			name: "Abzüge",
			value: deductions.text,
		},
		{
			name: "MwSt.",
			value: mwst.text,
		},
		{
			name: "Total",
			value: total.text,
		},
		// TODO: Verrechnet
		// TODO: Nicht verrechnet
	];

	const handleSave = async (event: React.MouseEvent<HTMLButtonElement>) => {
		if (jobDocument.status !== JobStatusEnum.IN_PROGRESS) {
			showToast(
				"Rabatte können nur bei pendendten Aufträgen geändert werden",
				"error"
			);
			return;
		}

		const { success, data, error } = await handleUpdate(
			event,
			jobDocument.id
		);
		if (success && data) {
			// TODO: DW-703 Update job document global state
			window.location.reload();
		}
	};

	return (
		<Portal container={container.current}>
			<RightSidebar title="Total" onClose={onClose}>
				<tbody
					style={{
						padding: "20px",
						borderBottom: "1px solid #e0e0e0",
					}}
				>
					{rows.map((row) => (
						<tr key={row.name}>
							<td
								style={{
									width: "100px",
									fontWeight: "bold",
								}}
							>
								{row.name}
							</td>
							<td>{row.value}</td>
						</tr>
					))}
				</tbody>
				<div
					style={{
						padding: "20px",
					}}
				>
					<CustomTextFieldWithPercentage
						label="Rabatt auf Tarifpositionen"
						name="discount_work"
						value={formData.discount_work || ""}
						onChange={handleInputChange}
						fullWidth
						validationError={null}
					/>
					<CustomTextFieldWithPercentage
						label="Rabatt auf Materialien"
						name="discount_material"
						value={formData.discount_material || ""}
						onChange={handleInputChange}
						fullWidth
						validationError={null}
					/>
					<Button
						variant="contained"
						color="primary"
						onClick={handleSave}
						sx={{ mt: 2 }}
					>
						Speichern
					</Button>
				</div>
			</RightSidebar>
		</Portal>
	);
};
