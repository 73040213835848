import {
	Button,
	IconButton,
	Slider,
	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material";
import {
	PdfTemplateConfiguration,
	usePdfTemplateConfigurationContext,
} from "../../context/PdfTemplateConfigurationContext";
import { useState } from "react";
import { showNotification } from "../../store/Central/selectors";
import { v4 as uuidv4 } from "uuid";
import { Logger } from "@/lib/logger/Logger";
import SaveIcon from "@mui/icons-material/Save";
import { RestartAlt } from "@mui/icons-material";
import clsx from "clsx";
import { supabase } from "@/lib/supabase";
import { StorageBucketsEnum } from "../../types/enums";
import { useCentralStore } from "../../store/Central";
import { Alignment } from "../../pdf-templates/pdf-templates/types";

export const PdfTemplateLogo = ({
	pdfTemplateConfiguration,
}: {
	pdfTemplateConfiguration: PdfTemplateConfiguration | undefined;
}) => {
	const [logoSize, setLogoSize] = useState<number | null>(
		pdfTemplateConfiguration?.general.logoSize ?? null
	);

	// This holds the blob64 address for the image when uploaded
	const [logoPic, setLogoPic] = useState<string | null>(null);
	const { organizationId } = useCentralStore((state) => ({
		organizationId: state.organization?.id,
	}));

	const { updatePdfTemplateConfiguration } =
		usePdfTemplateConfigurationContext();

	// This holds the source url for the uploaded image
	const [logoPicSrc, setLogoPicSrc] = useState<string | null>(null);
	const [uploading, setUploading] = useState(false);

	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		setUploading(true);
		if (!e.target.files || e.target.files.length <= 0) return;

		const file = e.target.files[0];

		if (file.size > 5 * 1024 * 1024) {
			showNotification({
				message: "Bitte wählen Sie eine Datei kleiner als 5MB",
				type: "error",
			});

			return;
		}

		const reader = new FileReader();

		reader.onloadend = () => {
			setLogoPic(reader.result as string);
		};

		reader.readAsDataURL(file);

		const imageName = `logos/${uuidv4()}/${file.name}`;

		const { error, data } = await supabase.storage
			.from(StorageBucketsEnum.PUBLIC_IMAGES)
			.upload(`${organizationId}/${imageName}`, file);

		if (error) {
			Logger.error("Error uploading");
			showNotification({
				message: "Fehler beim Hochladen des Logos",
				type: "error",
			});

			return;
		}

		setLogoPicSrc(data.path);
		setUploading(false);
	};

	const resetImg = async () => {
		updatePdfTemplateConfiguration("general", {
			logoImgurl: "",
		});
		setLogoPicSrc("");
		setLogoPic("");
	};

	const SaveImgSettings = async () => {
		const image_src = logoPicSrc
			? supabase.storage
					.from(StorageBucketsEnum.PUBLIC_IMAGES)
					.getPublicUrl(logoPicSrc as string, {}).data.publicUrl
			: pdfTemplateConfiguration?.general.logoImgurl;

		await updatePdfTemplateConfiguration("general", {
			logoImgurl:
				image_src ?? pdfTemplateConfiguration?.general.logoImgurl,
			logoSize: logoSize as number,
		});
	};

	return (
		<div className="w-full flex flex-col gap-4">
			<div className="flex flex-col items-center gap-4">
				<div
					className={clsx(
						"h-[20mm] relative  border  overflow-hidden max-w-60",
						{
							"w-full": !(
								logoPic ||
								pdfTemplateConfiguration?.general.logoImgurl
							),
						}
					)}
				>
					{uploading && (
						<div className="absolute flex items-center justify-center top-0 left-0 w-full h-full">
							<p>Hochladen...</p>
						</div>
					)}
					{(pdfTemplateConfiguration?.general.logoImgurl ||
						logoPic) && (
						<img
							style={{
								opacity: uploading ? 0.5 : 1,
							}}
							alt="logo"
							src={
								logoPic ??
								pdfTemplateConfiguration?.general.logoImgurl
							}
							className=" h-full object-cover aspect-auto"
						/>
					)}
				</div>
				<input
					id="logo"
					name="logo"
					onChange={handleFileChange}
					type="file"
					className="hidden"
				/>
				<Button onClick={() => {}} disabled={uploading}>
					<label htmlFor="logo">Hochladen</label>
				</Button>
				<Slider
					onChange={(_, v) => {
						setLogoSize(v as number);
					}}
					defaultValue={18}
					aria-label="Disabled slider"
					value={logoSize ?? 18}
					min={15}
					max={80}
				/>
				{logoSize}
			</div>

			<ToggleButtonGroup
				color="primary"
				value={pdfTemplateConfiguration?.general.logoAlignment}
				className="flex justify-center"
				exclusive
				onChange={(e, v) => {
					updatePdfTemplateConfiguration("general", {
						logoAlignment: v as Alignment,
					});
				}}
				aria-label="Platform"
			>
				<ToggleButton value="left">Links</ToggleButton>
				<ToggleButton value="center">Zentriert</ToggleButton>
				<ToggleButton value="right">Rechts</ToggleButton>
			</ToggleButtonGroup>

			<div className="w-full flex justify-end">
				<IconButton
					disabled={!logoPicSrc}
					color="primary"
					onClick={resetImg}
				>
					<RestartAlt />
				</IconButton>
				<IconButton
					disabled={
						logoPicSrc ===
							pdfTemplateConfiguration?.general.logoImgurl &&
						pdfTemplateConfiguration?.general.logoSize == logoSize
					}
					color="primary"
					onClick={SaveImgSettings}
				>
					<SaveIcon />
				</IconButton>
			</div>
		</div>
	);
};
