import { StateCreator } from "zustand";
import {
	adjustJobStatus,
	initialState,
	JobEntityTypeWithDocuments,
} from "./utils";
import { Logger } from "@/lib/logger/Logger";
import {
	goBackOrForwardDays,
	handleDatabaseOperation,
} from "../../lib/utils/utils-functions";
import { supabase } from "@/lib/supabase";
import {
	JobEntityType,
	JobWithShare,
	SupabaseShareEnitityViewEnum,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { State } from "./types";
import { JobStatusEnum } from "@/lib/types/job";
import { useCentralStore } from "../Central";
import { showNotification } from "../Central/selectors";
import { produce } from "immer";
import { TpTier, TpValue } from "../../types/enums";

export interface JobsSlice extends State {
	latestJobs: JobWithShare[];
	fetchLatestJobs: (jobCount: number) => Promise<void>;
	fetchJob: (jobId: number) => Promise<void>;
	createJob: () => Promise<JobWithShare | null>;
	updateGuarantor: (guarantorId: string | null) => Promise<void>;
	deleteJob: (job: JobEntityTypeWithDocuments) => Promise<void>;
	setJob: (job: JobWithShare) => void;
	changeJobStatus: (
		job: JobEntityTypeWithDocuments,
		newStatus: JobStatusEnum
	) => Promise<boolean>;
	updateJob: (
		newJob: JobEntityType,
		field: "title" | "code" | "status",
		value: string | number
	) => Promise<boolean>;
	reset: () => void;
}

export const createJobStore: StateCreator<JobsSlice> = (set, get) => ({
	...initialState,
	latestJobs: [],
	setJob: (job: JobWithShare) => {
		// To escape type errors the Job Document must be set from the existing zustand cache
		// since it won't be passed from the argument
		const jobDocuments = get().jobDocuments;
		set({
			job: {
				...job,
				job_documents: jobDocuments,
			},
		});
	},
	createJob: async () => {
		set({
			jobLoading: true,
		});
		const { client, organization } = useCentralStore.getState();

		if (!client || !organization || !organization?.id) {
			showNotification({
				message:
					"Fehler beim Erstellen des Auftrags durch fehlende Daten.",
				type: "error",
			});
			Logger.error(
				"Unexpected error creating job. Missing client or organization data."
			);
			return null;
		}
		const taxRateForJob = organization.mwst_percentage ?? 8.1;
		const tpValueForJob =
			client.default_tp_value !== null
				? client.default_tp_value
				: organization.default_tp_value ?? TpValue.NEW;
		const tpTierForJob =
			client.default_tp_tier !== null
				? client.default_tp_tier
				: organization.default_tp_tier ?? TpTier.PP2;

		const { data, error } = await supabase
			.from(SupabaseTableEnum.JOBS)
			.insert({
				code: "", // If code = '' it will be set on insert on the db
				client_id: client.id,
				title: "Neuer Auftrag",
				status: JobStatusEnum.IN_PROGRESS,
				organization_id: organization?.id,
				incoming_order_date: new Date().toISOString(),
				outgoing_order_date: goBackOrForwardDays(7),
				tax_rate: taxRateForJob,
				guarantor_id: client.guarantor_id,
				tp_tier: tpTierForJob,
				tp_value: tpValueForJob,
			})
			.select()
			.single();

		if (error) {
			showNotification({
				message: "Fehler beim Erstellen des Auftrags",
				type: "error",
			});
			Logger.error(error);
			return null;
		}

		if (data) {
			Logger.info({
				message: "Auftrag erstellt",
				type: "success",
			});
			set(
				produce((state) => {
					state.job = {
						...data,
						job_documents: [],
					};
					state.jobDocuments = [];
					state.jobList[JobStatusEnum.IN_PROGRESS].jobs.unshift({
						...data,
						job_documents: [],
					});
					state.jobList[JobStatusEnum.IN_PROGRESS].jobCount += 1;
				})
			);
			set({
				jobLoading: false,
			});
			return {
				...data,
				job_documents: [],
				shared_ids: [],
				connect_relationship_id: null,
			};
		} else {
			showNotification({
				message: "Fehler beim Erstellen des Auftrags",
				type: "error",
			});
			return null;
		}
	},
	fetchJob: async (jobId: number) => {
		if (!jobId) {
			Logger.error("Job id is not set");
			return;
		}

		const { data, error } = await supabase
			.from(SupabaseShareEnitityViewEnum.JOBS_WITH_SHARES)
			.select(
				`*, ${SupabaseTableEnum.JOB_DOCUMENTS} (*,${SupabaseTableEnum.FILES}(*))`
			)
			.eq("id", jobId)
			.single();
		if (error || !data) {
			set({
				job: null,
				jobDocuments: [],
			});
			Logger.error(error);
			return;
		}

		const { job_documents, ...job } = data;
		set(
			produce((state) => {
				state.job = {
					...job,
					job_documents: job_documents ?? [],
					shared_ids: job.shared_ids?.filter((j) => j),
				};
				state.jobDocuments = job_documents ?? [];
				state.jobList[job.status as JobStatusEnum].jobs = state.jobList[
					job.status as JobStatusEnum
				].jobs.map((j: JobEntityTypeWithDocuments) =>
					j.id === job.id
						? {
								...job,
								job_documents: job_documents ?? [],
							}
						: j
				);
			})
		);
	},
	deleteJob: async (job: JobEntityTypeWithDocuments) => {
		set(
			produce((state) => {
				state.job = null;
				state.jobList[job.status as JobStatusEnum].jobCount -= 1;
				state.jobList[job.status as JobStatusEnum].jobs = state.jobList[
					job.status as JobStatusEnum
				].jobs.filter((j: JobEntityType) => j.id !== job.id);
			})
		);
		const { error } = await supabase
			.from(SupabaseTableEnum.JOBS)
			.delete()
			.eq("id", job.id as number);

		if (error) {
			Logger.error("Error deleting job", error);
			set(
				produce((state) => {
					state.job = job;
					state.jobList[job.status as JobStatusEnum].jobCount += 1;
					state.jobList[job.status as JobStatusEnum].jobs.shift(job);
				})
			);

			showNotification({
				message: "Fehler beim Löschen des Auftrags",
				type: "success",
			});
			return;
		}
		showNotification({
			message: "Auftrag gelöscht",
			type: "success",
		});
	},

	/**
	 * updateGuarantor
	 * Allowed if: No job document has been created that has job items
	 */
	updateGuarantor: async (guarantorId: string | null) => {
		const { job } = get();
		if (!job) {
			showNotification({
				message: "Auftrag ist nicht gesetzt",
				type: "error",
			});
			return;
		}

		const someJobDocumentWithJobItems = get().jobDocuments.some(
			({ id }) => get().jobItemsForDocuments[id]?.length > 0
		);
		if (someJobDocumentWithJobItems) {
			showNotification({
				message:
					"Der Garant kann nicht mehr geändert werden, da bereits Dokumente mit Positionen erstellt wurden",
				type: "error",
			});
			return;
		}

		const { data, error } = await supabase
			.from(SupabaseTableEnum.JOBS)
			.update({ guarantor_id: guarantorId })
			.eq("id", job.id as number);

		if (error) {
			showNotification({
				message: "Fehler beim Aktualisieren des Garanten",
				type: "error",
			});
			Logger.log(error);
			return;
		}

		if (data) {
			set({
				job: {
					...job,
					guarantor_id: guarantorId,
				},
			});
			showNotification({
				message: "Garant aktualisiert",
				type: "success",
			});
		}
	},

	changeJobStatus: async (
		job: JobEntityTypeWithDocuments,
		newStatus: JobStatusEnum
	) => {
		set(
			produce((state) => {
				adjustJobStatus(
					state,
					job,
					newStatus,
					job.status as JobStatusEnum
				);
			})
		);
		const { error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.JOBS)
				.update({ status: newStatus })
				.eq("id", job.id as number)
		);

		if (error) {
			set(
				produce((state) => {
					// Revert the changes
					adjustJobStatus(
						state,
						job,
						job.status as JobStatusEnum,
						newStatus
					);
				})
			);
			return false;
		}

		return true;
	},

	updateJob: async (
		newJob: JobEntityType,
		field: "title" | "code" | "status" | "patient_id",
		value: string | number
	) => {
		let previousJob: JobEntityTypeWithDocuments | null = null;
		if (field !== "status") {
			set(
				produce((state) => {
					previousJob = state.job;
					state.job = { ...state.job, [field]: value };
					state.jobList[newJob.status as JobStatusEnum].jobs =
						state.jobList[newJob.status as JobStatusEnum].jobs.map(
							(j: JobEntityTypeWithDocuments) => {
								if (j.id === newJob.id) {
									previousJob = j;
									return { ...j, [field]: value };
								}
								return j;
							}
						);
				})
			);
		}
		const { error } = await supabase
			.from(SupabaseTableEnum.JOBS)
			.update({ [field]: value })
			.eq("id", newJob.id as number);

		if (error) {
			set(
				produce((state) => {
					state.job = previousJob;
					state.jobList[newJob.status as JobStatusEnum].jobs =
						state.jobList[newJob.status as JobStatusEnum].jobs.map(
							(j: JobEntityType) =>
								j.id === newJob.id ? previousJob : j
						);
				})
			);
			Logger.error(`Error updating job ${field}`, error);
			return false;
		}

		return true;
	},

	fetchLatestJobs: async (jobCount) => {
		const organizationId = useCentralStore.getState().organization?.id;

		if (!organizationId) {
			Logger.error("Organization Id not present");
			return;
		}

		const { data, error } = await supabase
			.from(SupabaseShareEnitityViewEnum.JOBS_WITH_SHARES)
			.select()
			.eq("organization_id", organizationId)
			.order("created_at", { ascending: false })
			.limit(jobCount);

		if (error) {
			Logger.error("Error occurred fetching latest jobs", error);
			return;
		}

		set({
			latestJobs: data,
		});
	},

	reset: () => {
		set(initialState);
	},
});
