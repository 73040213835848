import { useCentralStore } from "@/dentlab/src/store/Central";
import { JobDocumentWithFiles } from "../../job-document/job-document.component";
import { NEW_DOCUMENT_OPTIONS } from "./new-document-options";
import { useJobStore } from "@/dentlab/src/store/Jobs";
import { JobDocumentTypeEnum } from "../../job-document/job-document.types";
import { Autocomplete, Button, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

interface Option {
	label: string;
	id: number;
}

export const NewJobDocumentArea: React.FC = () => {
	const jobDocumentTemplateOptions = useCentralStore((state) =>
		Object.values(state.jobDocumentTemplatesLookup).map((template) => ({
			label: template.title,
			id: template.id,
			type: template.type,
		}))
	);
	const { createJobDocument, createJobDocumentFromTemplate } = useJobStore(
		(state) => ({
			createJobDocument: state.createJobDocument,
			createJobDocumentFromTemplate: state.createJobDocumentFromTemplate,
		})
	);

	const { isCreateJobDocumentAllowed } = useJobStore((state) => ({
		isCreateJobDocumentAllowed: state.isCreateJobDocumentAllowed,
	}));

	return (
		<div className="p-4">
			<div className="flex flex-row gap-2 items-center flex-wrap">
				{NEW_DOCUMENT_OPTIONS.map((option) => (
					<Button
						variant="outlined"
						key={option.value}
						onClick={() => {
							createJobDocument(option.value);
						}}
						disabled={
							!isCreateJobDocumentAllowed(option.value).isAllowed
						}
						startIcon={<AddIcon />}
						size="large"
					>
						{(() => {
							switch (option.value) {
								case JobDocumentTypeEnum.Quotation:
									return "Leerer Kostenvoranschlag";
								case JobDocumentTypeEnum.DeliveryNote:
									return "Leerer Lieferschein";
								case JobDocumentTypeEnum.CreditNote:
									return "Leere Gutschrift";
								case JobDocumentTypeEnum.MATERIALS:
									return "Leere Materialienliste";
								default:
									return option.label;
							}
						})()}
					</Button>
				))}
				<Autocomplete
					sx={{ width: "300px" }}
					onChange={(event, value) => {
						createJobDocumentFromTemplate((value as Option).id);
					}}
					getOptionDisabled={(option) =>
						!isCreateJobDocumentAllowed(
							option.type as JobDocumentTypeEnum
						).isAllowed
					}
					options={jobDocumentTemplateOptions}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Aus Vorlage"
							InputProps={{
								...params.InputProps,
								type: "search",
							}}
						/>
					)}
				/>
			</div>
		</div>
	);
};
