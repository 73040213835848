import { useShared } from "@/dentlab/src/hooks/useActivities";
import { useSendEmail } from "@/dentlab/src/hooks/useSendEmail";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { Logger } from "@/lib/logger/Logger";
import {
	ActivityTypeEnum,
	FileWithShare,
	isShared,
	JobEntityType,
	JobWithShare,
	PatientEntityType,
	PatientWithShare,
} from "@/lib/supabase/supabaseTypes";
import { PractitionerOptionType } from "../share-dialog/practitioner-emails-autocomplete";
import { ShareDialog } from "../share-dialog/share-dialog.component";
import { showNotification } from "@/dentlab/src/store/Central/selectors";

// This component is used to share files (non-pdf files) relating to a patient or a job
//
// If a job or patient is set the corresponding job or patient will be attached to the file
// in dentconnect
export const ShareFileButton = ({
	file,
	open,
	setFile,
	setOpen,
}: {
	file: FileWithShare;
	setFile: (file: FileWithShare) => void;
	open: boolean;
	setOpen: (val: boolean) => void;
	associatedEntity?:
		| {
				job: JobEntityType | JobWithShare;
				patient: null;
		  }
		| {
				job: null;
				patient?: PatientEntityType | PatientWithShare;
		  };
}) => {
	const { addSharedFileActivity } = useShared();
	const { connectRelationshipId } = useCentralStore((state) => ({
		connectRelationshipId: state.connectRelationshipId,
	}));

	const handleShare = async () => {
		if (!file.file_name || !file.id || !connectRelationshipId) {
			showNotification({
				message: "Datei konnte nicht geteilt werden",
				type: "error",
			});
			return;
		}
		let activityType = ActivityTypeEnum.SHARED_FILE;
		if (file.job_document_id !== null) {
			activityType = ActivityTypeEnum.SHARED_DOCUMENT;
		}
		const { data, error } = await addSharedFileActivity(
			connectRelationshipId,
			file.id,
			activityType
		);

		if (error || !data) {
			showNotification({
				message: "Datei konnte nicht geteilt werden",
				type: "error",
			});
			Logger.error(
				"ShareFileButton: handleShare: activitySuccess is false. Error: ",
				error
			);
			return;
		}
		setFile({
			...file,
			shared_ids: [data.id],
		});
	};

	const { sendSharedFileEmailTemplate } = useSendEmail();

	const handleNotify = async (
		practitioners: PractitionerOptionType[],
		message: string
	) => {
		const { label: emailAddress } = practitioners[0];

		sendSharedFileEmailTemplate({
			emailAddress,
			fileName: file.file_name ?? "Kein Dateiname",
			link: "dentconnect.dentdesk.ch",
			message,
		});

		setOpen(false);
	};
	const shareDialog = (
		<ShareDialog
			open={open}
			onClose={() => setOpen(false)}
			entityName={file?.file_name ?? "Datei"}
			shared={isShared(file)}
			onShare={handleShare}
			onUnshare={() => {}}
			onNotify={handleNotify}
		/>
	);

	return <>{shareDialog}</>;
};
